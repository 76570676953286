import {z} from 'zod';
import {id} from '../global/globalModels';

export const point = z.tuple([z.number(), z.number()]);

export type Point = z.infer<typeof point>;

export const characteristic = z.object({
    id: id,
    term: z.string(),
});

export type Characteristic = z.infer<typeof characteristic>;

export const resourceType = z.object({
    id: id,
    icon: z.string(),
    name: z.string(),
    pluralName: z.string(),
    hasArea: z.boolean(),
    details: z.boolean().optional(),
    hasCapacity: z.boolean(),
    hasCalendarSync: z.boolean(),
    hasMultiBooking: z.boolean(),
    isFavorable: z.boolean(),
    isPresence: z.boolean(),
    allowsIndividualTimes: z.boolean(),
});

export type ResourceType = z.infer<typeof resourceType>;

export const resourceTypeFilter = z.object({
    resourceType: id,
    offset: z.number().min(0),
    limit: z.number().min(0).nullable(),
    minCapacity: z.number().min(0),
    characteristics: z.array(id),
});

export type ResourceTypeFilter = z.infer<typeof resourceTypeFilter>;

export const resource = z.object({
    id: id,
    name: z.string(),
    resourceType: id,
    room: id,
    vertices: z.array(point).min(1),
    characteristics: z.array(z.string()),
    capacity: z.number().min(0),
});

export type Resource = z.infer<typeof resource>;

export const userFavoriteResource = id;
export type UserFavoriteResource = z.infer<typeof userFavoriteResource>;
