import {translate} from '../../../shared/mixins/translation';
import PresentListDayBooking from './PresentListDayBooking.js';

export default class PresentListItem {
    constructor(json) {
        this.dayBookings = json.dayBookings.map(
            dayBooking => new PresentListDayBooking(dayBooking)
        );
        this.userId = json.userId;
        this.firstName = json.firstName;
        this.lastName = translate(json.lastName);
        this.email = json.email;
        this.initials = json.initials;
        this.profileImage = json.profileImage;
        this.teamsChatLink = json.teamsChatLink;
        this.teamsCallLink = json.teamsCallLink;
    }
}
