import {z} from 'zod';

export const id = z.string().uuid();

export const nullableId = id.nullable().default(null);

export const nullableString = z.string().nullable().default(null);

export const nonNegativeInt = z.number().int().nonnegative();

export const localDateTime = z
    .string()
    .datetime({local: true})
    .transform(datetime => new Date(datetime));
