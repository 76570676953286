import type {
    NavigationGuardReturn,
    RouteLocationAsRelativeGeneric,
    RouteLocationNormalized,
} from 'vue-router';
import {useFeatureFlagStore} from '../../stores/featureFlagStore';
import {useSpaceSelectionStore} from '../../stores/booking/spaceSelectionStore';
import {useUserStore} from '../../stores/userStore';

export async function beforeEnterBooking(
    to: RouteLocationNormalized
): Promise<NavigationGuardReturn> {
    const isAllowed = await useFeatureFlagStore().newBooking.isReady();

    if (!isAllowed) {
        return {name: 'Home'};
    }

    if (to.name === 'BookingMap') {
        return;
    }

    const {selectedRoomId} = useSpaceSelectionStore();

    if (selectedRoomId) {
        return getBookingMapTargetRoute(selectedRoomId, to);
    }

    const {user} = useUserStore();

    if (user?.primaryRoom?.id) {
        return getBookingMapTargetRoute(user.primaryRoom.id, to);
    }
}

function getBookingMapTargetRoute(
    roomId: string,
    to: RouteLocationNormalized
): RouteLocationAsRelativeGeneric | undefined {
    if (to.name === 'BookingMap' && to.params.roomId === roomId) {
        return;
    }
    return {
        name: 'BookingMap',
        params: {roomId: roomId},
    };
}
