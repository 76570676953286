import api from '../api';
import PresentListItem from '../models/view/PresentListItem';
import RoomPlanDetailsView from '../models/view/RoomPlanDetailsView';
import {kioskModeWhiteLabeling} from '../models/kioskMode/kioskModeWhiteLabelData';
import {kioskModeToken, type KioskModeToken} from '../models/kioskMode/kioskModeToken';

export function getKioskModeData(roomId: string, token: string) {
    return api
        .get(`/api/kiosk-mode/${roomId}?token=${token}`, false)
        .then(api.handle)
        .then(raw => {
            return {
                room: new RoomPlanDetailsView(raw.room),
                presentData: (raw.presentData as unknown[]).map(
                    (singleRaw: unknown) => new PresentListItem(singleRaw)
                ),
                whitelabelData: kioskModeWhiteLabeling.parse(raw.whitelabelData),
            };
        });
}

export function getKioskModeZone(zoneId: string, token: string) {
    return api.get(`/api/kiosk-mode/zone/${zoneId}?token=${token}`).then(api.handle);
}

export function getKioskModeRoomImage(roomId: string, token: string) {
    return api
        .get(`/api/kiosk-mode/${roomId}/image?token=${token}`, false)
        .then(response => response.blob())
        .catch(api.error)
        .then(blob => (URL || webkitURL).createObjectURL(blob));
}

export function retrieveKioskModeToken(code: string): Promise<KioskModeToken> {
    return api
        .get('/api/kiosk-mode/code?code=' + code, false)
        .then(api.handle)
        .catch(api.error)
        .then(raw => kioskModeToken.parse(raw));
}

export function exchangeOldToken(roomId: string, oldToken: string) {
    return api
        .get(`/api/kiosk-mode/update-token/${roomId}?token=${oldToken}`)
        .then(api.handle)
        .catch(api.error)
        .then(raw => kioskModeToken.parse(raw));
}

export function deleteKioskModeToken(roomId: string, token: string) {
    return api
        .delete(`/api/kiosk-mode/${roomId}/token?token=${token}`)
        .then(api.handle)
        .catch(api.error)
        .then(raw => kioskModeToken.parse(raw));
}
