import type {RouteLocationNormalized, RouteLocationRaw} from 'vue-router';
import {unwrapRouteQueryValue} from '../../../shared/helpers/routeHelper';
import {exchangeOldToken} from '../../../api/repositories/KioskModeRepository';

const oldTokenKey = 'kioskModeToken';

/**
 * Exchanges the old token - if present - with the new token before entering the respective routes
 */
export async function beforeEnterKioskMode(
    to: RouteLocationNormalized
): Promise<RouteLocationRaw | void> {
    const token = 'token' in to.query ? unwrapRouteQueryValue(to.query.token) : null;
    const oldToken = localStorage.getItem(oldTokenKey);

    localStorage.removeItem(oldTokenKey);

    if (token) {
        return;
    }

    const roomId = typeof to.params.roomId === 'string' ? to.params.roomId : null;

    if (!roomId || !oldToken) {
        return {name: 'KioskModeCode'};
    }

    try {
        const result = await exchangeOldToken(roomId, oldToken);

        return {name: to.name, params: {roomId}, query: {token: result.token}};
    } catch {
        return {name: 'KioskModeCode'};
    }
}
