import SeatView from './SeatView';
import ZoneView from './ZoneView';

export default class ResourceCheckView {
    constructor(json) {
        this.type = json.type;
        if (this.type === 'checkin' || this.type === 'occupied') {
            this.dayBooking = json.dayBooking;
        }

        this.resourceType = json.resourceType;
        if (this.resourceType === 'seat') {
            this.resource = new SeatView(json.resource);
            return;
        }

        this.resource = new ZoneView(json.resource);
    }
}
