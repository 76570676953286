import {z} from 'zod';

export const kioskModeWhiteLabeling = z.object({
    applicationTitle: z.string().nullable(),
    customColors: z.boolean(),
    favicon: z.string().nullable(),
    hoverColor: z.string().nullable(),
    kioskBackgroundColor: z.string().nullable(),
    logo: z.string().nullable(),
    primaryColor: z.string().nullable(),
    secondaryColor: z.string().nullable(),
});

export type KioskModeWhiteLabeling = z.infer<typeof kioskModeWhiteLabeling>;
