import type {LocationQueryValue} from 'vue-router';

export function unwrapRouteQueryValue(
    queryValue: LocationQueryValue | LocationQueryValue[]
): LocationQueryValue {
    if (queryValue instanceof Array) {
        return queryValue[0];
    }

    return queryValue;
}
