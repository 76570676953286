import api from '../../api';
import {characteristic, type Characteristic} from '../../models/booking/resourceModel';
import {spaceStructure, type SpaceStructure} from '../../models/booking/spaceModels';

export async function getSpaceStructure(): Promise<SpaceStructure> {
    return api
        .get('/api/space/list')
        .then(api.handle)
        .catch(api.error)
        .then(raw => spaceStructure.parse(raw));
}

export async function getLocationCharacteristics(locationId: string): Promise<Characteristic[]> {
    return api
        .get(`/api/location/${locationId}/characteristic/list`)
        .then(api.handle)
        .catch(api.error)
        .then(raw => characteristic.array().parse(raw));
}
