import {z} from 'zod';
import {id} from '../global/globalModels';

export const resourceCount = z.object({
    locations: z.number().optional(),
    floors: z.number().optional(),
    rooms: z.number().optional(),
    resources: z
        .object({
            resourceType: id,
            count: z.number(),
        })
        .array(),
});

const room = z.object({
    id: id,
    name: z.string(),
    roomPlanWidth: z.number().min(0),
    roomPlanHeight: z.number().min(0),
    roomPlanUrl: z.string(),
    counts: resourceCount,
});

export type BookingRoom = z.infer<typeof room>;

const floor = z.object({
    id: id,
    name: z.string(),
    rooms: room.array(),
    counts: resourceCount,
});

export type BookingFloor = z.infer<typeof floor>;

const location = z.object({
    id: id,
    name: z.string(),
    floors: floor.array(),
    counts: resourceCount,
});

export type BookingLocation = z.infer<typeof location>;

export const spaceStructure = z.object({
    locations: location.array(),
    counts: resourceCount,
});

export type SpaceStructure = z.infer<typeof spaceStructure>;
