import ResourceRepository from '../../api/repositories/ResourceRepository';

/**
 * @param {RouteLocationNormalized} to
 * @return {NavigationGuardResult}
 */
export const handleQrCode = async to => {
    const resourceId = to.params.resourceId;

    if (!resourceId) {
        return {name: 'Home'};
    }

    try {
        const resourceCheck = await ResourceRepository().check(resourceId);

        switch (resourceCheck?.resourceType) {
            case 'seat':
                return {name: 'DayBookingConfirmationSeat', params: {seatId: resourceId}};
            case 'zone':
                return {name: 'DayBookingConfirmationZone', params: {zoneId: resourceId}};
            case 'meeting_room':
                return {
                    name: 'DayBookingConfirmationMeetingRoom',
                    params: {meetingRoomId: resourceId},
                };
            default:
                return {name: 'Home'};
        }
    } catch {
        return {name: 'Home'};
    }
};
