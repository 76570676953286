import SeatView from './SeatView';
import ZoneView from './ZoneView';

export default class PresentListDayBooking {
    constructor({id, type, resource, date, from, until}) {
        this.id = id;
        this.type = type;
        this.date = date;
        this.from = from;
        this.until = until;

        switch (this.type) {
            case 'seat':
                this.resource = new SeatView(resource);
                break;
            case 'zone':
                this.resource = new ZoneView(resource);
                break;
            default:
                this.resource = null;
        }
    }
}
