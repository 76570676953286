// TODO This repository is only used for mocking booking 2.0 data

import {type Resource} from '../models/booking/resourceModel';

export enum BookingViewType {
    All = 'all',
    Location = 'location',
    Floor = 'floor',
    Room = 'room',
}

export type ResourceSummary = {
    resourceTypeId: string;
    count: number;
};

export async function getResourceSummaryForSpace(
    _date: Date,
    _spaceId: string | null,
    _spaceType: BookingViewType,
    _searchTerm: string | null
): Promise<ResourceSummary[]> {
    return [];
    // const roomId = spaceType === BookingViewType.Room ? spaceId : null;
    // const resources = await getMapResources(roomId);

    // const grouped = groupBy(
    //     resources.filter(getResourceFilterFn(searchTerm)),
    //     resource => resource?.typeId
    // );

    // return Object.keys(grouped).map(resourceTypeId => ({
    //     resourceTypeId,
    //     count: grouped[resourceTypeId].length,
    // }));
}

export async function getResourcesForSpace(
    _date: Date,
    _spaceId: string | null,
    _spaceType: BookingViewType,
    _resourceTypeId: string,
    _searchTerm: string | null,
    _offset: number,
    _limit: number
): Promise<Resource[]> {
    return [];
    // await new Promise(resolve => setTimeout(resolve, faker.number.int({min: 800, max: 1200})));
    // const roomId = spaceType === BookingViewType.Room ? spaceId : null;
    // const resources = await getMapResources(roomId);

    // searchTerm = searchTerm?.toLowerCase() ?? '';

    // return resources
    //     .filter(resource => resource.typeId === resourceTypeId)
    //     .filter(getResourceFilterFn(searchTerm));
}
