import {defineStore} from 'pinia';
import {computedAsync, until, useLocalStorage} from '@vueuse/core';
import type {
    BookingFloor,
    BookingLocation,
    BookingRoom,
    SpaceStructure,
} from '../../api/models/booking/spaceModels';
import {BookingViewType} from '../../api/repositories/BookingMockRepository';
import {computed, shallowRef} from 'vue';
import {useRoute} from 'vue-router';
import {getSpaceStructure} from '../../api/repositories/booking/SpaceRepository';

export const useSpaceSelectionStore = defineStore('spaceSelectionStore', () => {
    const selectedLocationId = useLocalStorage<string | null>('booking:locationId', null);
    const selectedFloorId = useLocalStorage<string | null>('booking:floorId', null);
    const selectedRoomId = useLocalStorage<string | null>('booking:roomId', null);

    const spaceStructureIsLoading = shallowRef(false);
    const spaceStructure = computedAsync<SpaceStructure | null>(
        () => getSpaceStructure(),
        null,
        spaceStructureIsLoading
    );

    const roomsWithParentData = computed(() => {
        const rooms =
            spaceStructure.value?.locations
                .map(location =>
                    location.floors.map(floor =>
                        floor.rooms.map(room => ({
                            ...room,
                            location,
                            floor,
                        }))
                    )
                )
                .flat(3) ?? [];

        return rooms;
    });

    const selectedLocation = computed<BookingLocation | null>(() => {
        return (
            spaceStructure.value?.locations?.find(
                location => location.id === selectedLocationId.value
            ) ?? null
        );
    });

    const selectedFloor = computed<BookingFloor | null>(() => {
        if (selectedFloorId.value === null) {
            return null;
        }

        return (
            selectedLocation.value?.floors.find(floor => floor.id === selectedFloorId.value) ?? null
        );
    });

    const selectedRoom = computed<BookingRoom | null>(() => {
        if (selectedRoomId.value === null) {
            return null;
        }

        return selectedFloor.value?.rooms.find(room => room.id === selectedRoomId.value) ?? null;
    });

    /**
     * Provides default selections when there's exactly one option at each level of the space hierarchy.
     * Properties will contain respective objects if exactly one exists, otherwise null
     */
    const singleSelectionDefaults = computed(() => ({
        location:
            spaceStructure.value?.locations.length === 1 ? spaceStructure.value.locations[0] : null,
        floor:
            selectedLocation.value?.floors.length === 1 ? selectedLocation.value.floors[0] : null,
        room: selectedFloor.value?.rooms.length === 1 ? selectedFloor.value.rooms[0] : null,
    }));

    function selectLocation(locationId: string) {
        selectedLocationId.value = locationId;
        selectedFloorId.value = null;
        selectedRoomId.value = null;
    }

    async function selectFloor(floorId: string) {
        await until(roomsWithParentData).toMatch(rooms => rooms.length > 0);
        const parentLocation = roomsWithParentData.value.find(
            room => room.floor.id === floorId
        )?.location;

        if (!parentLocation) {
            selectedFloorId.value = null;
            return;
        }

        selectLocation(parentLocation.id);

        selectedFloorId.value = floorId;
        selectedRoomId.value = null;
    }

    async function selectRoom(roomId: string) {
        await until(roomsWithParentData).toMatch(rooms => rooms.length > 0);
        const parentFloor = roomsWithParentData.value.find(room => room.id === roomId)?.floor;

        if (!parentFloor) {
            selectedRoomId.value = null;
            return;
        }

        await selectFloor(parentFloor.id);
        selectedRoomId.value = roomId;
    }

    function deselectLocation() {
        selectedLocationId.value = null;
        selectedFloorId.value = null;
        selectedRoomId.value = null;
    }

    function deselectFloor() {
        selectedFloorId.value = null;
        selectedRoomId.value = null;
    }

    function deselectRoom() {
        selectedRoomId.value = null;
    }

    const route = useRoute();

    /**
     * Holds the current value of ViewType and space id.
     * This is needed for retrieving data for each space.
     */
    const currentView = computed(() => {
        switch (route.name) {
            case 'BookingFloorSelection':
                return {type: BookingViewType.Location, id: selectedLocationId.value};
            case 'BookingRoomSelection':
                return {type: BookingViewType.Floor, id: selectedFloorId.value};
            case 'BookingMap':
                return {type: BookingViewType.Room, id: selectedRoomId.value};
            default:
                return {type: BookingViewType.All};
        }
    });

    const currentSpacePath = computed(() => {
        let path = '';

        if (!selectedLocation.value) {
            return path;
        }

        path += selectedLocation.value.name;

        if (!selectedFloor.value) {
            return path;
        }

        path += ` / ${selectedFloor.value.name}`;

        if (!selectedRoom.value) {
            return path;
        }

        return `${path} / ${selectedRoom.value.name}`;
    });

    return {
        selectedLocationId,
        selectedFloorId,
        selectedRoomId,
        spaceStructure,
        selectLocation,
        selectedLocation,
        selectFloor,
        selectedFloor,
        selectRoom,
        selectedRoom,
        deselectLocation,
        deselectFloor,
        deselectRoom,
        currentView,
        currentSpacePath,
        spaceStructureIsLoading,
        singleSelectionDefaults,
    };
});
