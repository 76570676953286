import configDay from '../../shared/mixins/configDay';
import API from '../api';
import PresentListItem from '../models/view/PresentListItem';
import ResourceCheckView from '../models/view/ResourceCheckView';
import {useToast} from '../../shared/components/toast/useToast.js';
import {translate} from '../../shared/mixins/translation.js';

export default () => ({
    check(resourceId) {
        return API.get(`/api/resource/check/${resourceId}`)
            .then(response => API.handle(response))
            .catch(error => {
                if (error.status === 403) {
                    useToast().error(translate('app.errors.not_authorized_resource'));

                    return Promise.reject({
                        status: error.status,
                        statusText: error.statusText,
                    });
                }

                return API.error(error);
            })
            .then(json => {
                return new ResourceCheckView(json);
            });
    },
    /**
     *
     * @param {string} name
     * @param {Date} date
     * @param {string} spaceId locationId or roomId
     * @returns {Promise<PresentListItem>} Promise array represents a list of PresentListItems
     */
    present(name, date, spaceId) {
        const searchParams = new URLSearchParams();

        if (name) {
            searchParams.append('name', name);
        }

        if (date) {
            searchParams.append('date', configDay.methods.formatApiDate(date));
        }

        const teamsType = sessionStorage.getItem('teamsType');

        if (teamsType) {
            searchParams.append('teamsType', teamsType);
        }

        return API.get(
            `/api/resource/present${spaceId ? '/' + spaceId : ''}?${searchParams.toString()}`
        )
            .then(API.handle)
            .catch(API.error)
            .then(raw => raw.map(singleRaw => new PresentListItem(singleRaw)));
    },
});
